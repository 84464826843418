import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import {catchError, retryWhen} from "rxjs/operators";
import {StudentErrorService} from "./student-error.service";



@Injectable({providedIn:'root'})
export class SurveyResponseService {
	private surveyResponse;

	constructor(
		private http: HttpClient,
		private errorService: StudentErrorService
	) { }

	generateHttpOptions = function(){
		return {
			headers: new HttpHeaders({ 
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': window.sessionStorage.authToken
			})
		};
	};

	fetchSurveyResponse = function(){
		var url = environment.baseStudentUrl;
		url += '/organizations/' +  window.sessionStorage.organizationName;
		url += "/surveys-launched/" + window.sessionStorage.surveyKey; 
		url += "/responses/" + window.sessionStorage.studentEmail;
		return this.http.get(url, this.generateHttpOptions()).pipe(
			retryWhen(this.errorService.checkForRetryCode()),
			catchError(this.errorService.displayErrorMessage)
		);
	};

	get cachedSurveyResponse(){
		return this.surveyResponse;
	};

	set cachedSurveyResponse(sr){
		this.surveyResponse = sr;
	};

	postSurveyResponse = function(json){
		var url = environment.baseStudentUrl;
		url += '/organizations/' + window.sessionStorage.organizationName;
		url += "/surveys-launched/" + window.sessionStorage.surveyKey;
		url += "/responses/";
		return this.http.post(url, json, this.generateHttpOptions()).pipe(
			retryWhen(this.errorService.checkForRetryCode()),
			catchError(this.errorService.displayErrorMessage)
		);
	};

	updateUser = function(json){
		var url = environment.baseStudentUrl;
		url += '/organizations/' + window.sessionStorage.organizationName;
		url +="/users/" + window.sessionStorage.studentEmail;
		return this.http.put(url, json, this.generateHttpOptions()).pipe(
			retryWhen(this.errorService.checkForRetryCode()),
			catchError(this.errorService.displayErrorMessage)
		);
	}

}
