import { Injectable } from '@angular/core';

@Injectable({providedIn:'root'})
export class UtilityService {

  constructor( ) { }

  FormatDate = function(date){
  	return date.toISOString().slice(0, new Date().toISOString().indexOf(".")).replace("T", " ")
  }

}
