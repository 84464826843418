import {Component} from '@angular/core';
import {Router} from '@angular/router';
import { Title } from '@angular/platform-browser';
import * as $ from "jquery";

import {StudentSurveyService} from '../services/student-survey.service';
import {SurveyResponseService} from '../services/survey-response.service';
import {UtilityService} from '../services/utility.service';

@Component({
	selector: 'app-root',
	templateUrl: './partials/survey.component.html',
	styleUrls: ['./styles/survey.component.scss', './styles/common.scss']
})
export class SurveyComponent {
	userAnswer;
	selectedAnswers = [];
	requestJson;
	requiredMessageVisible = false;
	userId;
	userFirstName;
	userLastName;
	nameInputsDisabled = false;
	levelOfInterestExpirationPeriod = 35;
	submitButtonHasBeenPressed = false;
	pageTitle = 'Insights Student Survey - Questions';

	static router: Router;

	constructor(
		private r: Router,
		private studentSurveyService: StudentSurveyService,
		private surveyResponseService: SurveyResponseService,
		private utility: UtilityService,
		private titleService: Title
	) {
		SurveyComponent.router = r;
	}

	ngOnInit() {
		this.fetchSurveyQuestions();
		this.userFirstName = window.sessionStorage.firstName;
		this.userLastName = window.sessionStorage.lastName;
		this.userId = window.sessionStorage.studentId;
		this.titleService.setTitle(this.pageTitle);
	}

	fetchSurveyQuestions() {
		var component = this;
		component.studentSurveyService.fetchSurvey().subscribe(function (response) {
			component.requestJson = response;
			$('#first-name-input').ready(function () {
				if (response.survey_launch_metadata.is_restricted === 1) {
					$('#first-name-input').prop('disabled', true);
					$('#last-name-input').prop('disabled', true);
					$('#first-name-input').attr('placeholder', '');
					$('#last-name-input').attr('placeholder', '');
					component.nameInputsDisabled = true;
				} else {
					if (component.userFirstName) {
						$('#first-name-input').prop('disabled', true);
					}
					if (component.userLastName) {
						$('#last-name-input').prop('disabled', true);
					}
				}

			})
		});
	}

	isQuestionSelected(question, answer) {
		if (this.selectedAnswers.indexOf('range' + question + answer) > -1) {
			return 'bg-info text-white';
		}
	}

	setAnswer(question, answer) {
		var currentUTCDate = new Date();
		var dateTimeUtc = this.utility.FormatDate(currentUTCDate);
		var extendedDate = new Date();
		extendedDate.setDate(currentUTCDate.getDate() + this.levelOfInterestExpirationPeriod);
		var expirationDateUtc = this.utility.FormatDate(extendedDate);
		this.userAnswer = this.requestJson.survey[0].questions[question].options[answer];
		this.userAnswer.email = window.sessionStorage.studentEmail;
		this.userAnswer.user_id = parseInt(this.userId);
		this.userAnswer.survey_key = window.sessionStorage.surveyKey;
		this.userAnswer.organization_name = window.sessionStorage.organizationName;
		this.userAnswer.level_of_interest_effective_at = dateTimeUtc;
		this.userAnswer.level_of_interest_expires_at = expirationDateUtc;
		this.selectedAnswers[question] = "range" + question + answer;
		if (this.requestJson.survey[0].questions[question].student_selected_response) {
			delete this.requestJson.survey[0].questions[question].student_selected_response;
		}
		this.requestJson.survey[0].questions[question] = Object.assign({"student_selected_response": this.userAnswer}, this.requestJson.survey[0].questions[question]);
	}

	verifyRequiredFields() {
		this.requiredMessageVisible = false;
		if ((this.userFirstName === undefined) || (this.userFirstName === null) || (this.userFirstName.length < 1)) {
			this.requiredMessageVisible = true;
			$('#first-name-input').addClass('required');
		}

		if ((this.userLastName === undefined) || (this.userLastName === null) || (this.userLastName.length < 1)) {
			this.requiredMessageVisible = true;
			$('#last-name-input').addClass('required');
		}
	}

	removeRequiredNotice(event) {
		event.target.classList.remove('required');
	}

	submitSurvey() {
		this.verifyRequiredFields();
		if(this.submitButtonHasBeenPressed){return;}
		this.submitButtonHasBeenPressed = true;
		var currentUTCDate = new Date();
		var dateTimeUtc = this.utility.FormatDate(currentUTCDate);
		var extendedDate = new Date();
		extendedDate.setDate(currentUTCDate.getDate() + this.levelOfInterestExpirationPeriod);
		var expirationDateUtc = this.utility.FormatDate(extendedDate);
		if (this.requiredMessageVisible === false || this.nameInputsDisabled === true) {
			for (var i = 0; i < this.requestJson.survey[0].questions.length; i++) {
				if (!this.requestJson.survey[0].questions[i].student_selected_response) {
					this.userAnswer = this.requestJson.survey[0].questions[i].options[this.requestJson.survey[0].questions[i].options.length - 1];
					this.userAnswer.email = window.sessionStorage.studentEmail;
					this.userAnswer.user_id = parseInt(this.userId);
					this.userAnswer.survey_key = window.sessionStorage.surveyKey;
					this.userAnswer.organization_name = window.sessionStorage.organizationName;
					this.userAnswer.level_of_interest_effective_at = dateTimeUtc;
					this.userAnswer.level_of_interest_expires_at = expirationDateUtc;
					this.requestJson.survey[0].questions[i] = Object.assign({"student_selected_response": this.userAnswer}, this.requestJson.survey[0].questions[i]);

				}
			}


			window.sessionStorage.internalKey = this.requestJson.survey[0].survey_metadata.internal_key;
			this.requestJson = Object.assign({"responded_date": dateTimeUtc}, this.requestJson);
			this.requestJson = Object.assign({"organization_name": this.requestJson.survey_launch_metadata.organization_name}, this.requestJson);
			this.requestJson = Object.assign({"respondent_metadata": {}}, this.requestJson);
			this.requestJson.respondent_metadata = Object.assign({"email": window.sessionStorage.studentEmail}, this.requestJson.respondent_metadata);
			this.requestJson.respondent_metadata = Object.assign({"first_name": this.userFirstName}, this.requestJson.respondent_metadata);
			this.requestJson.respondent_metadata = Object.assign({"last_name": this.userLastName}, this.requestJson.respondent_metadata);
			var component = this;

			this.surveyResponseService.postSurveyResponse(this.requestJson).subscribe(function (response) {
				component.surveyResponseService.cachedSurveyResponse = response;
				var updateStudentJson = {
					"organizations": [{"organization_name": window.sessionStorage.organizationName}],
					"user_roles": [{
						"organization_name": window.sessionStorage.organizationName,
						"role_name": "student",
					}],
					"email": window.sessionStorage.studentEmail,
					"first_name": component.userFirstName,
					"last_name": component.userLastName,
					"application_enabled": true,
				}
				var updateStudentPromise = component.surveyResponseService.updateUser(updateStudentJson);
				updateStudentPromise.subscribe(function (response) {
					if (response === "{}") {
						
					}
				});
				SurveyComponent.router.navigate(['/survey-review']);
			});
		} else {
			window.scrollTo(0, 0);
		}
	}
}



	
