import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { SurveyResponseService } from '../services/survey-response.service';
import { HttpClient } from '@angular/common/http'; 

@Component({
	selector: 'app-root',
	templateUrl: './partials/survey-review.component.html',
	styleUrls: ['./styles/survey-review.component.scss','./styles/common.scss']
})
export class SurveyReviewComponent {
	userAnswer;
	selectedAnswers = [];
	surveyResponse;
	pageTitle = 'Insights Student Survey - Completed';

	constructor(
		private surveyResponseService: SurveyResponseService,
		private titleService: Title
	){}

	ngOnInit() {
		this.titleService.setTitle(this.pageTitle);
		window.scrollTo(0, 0);
		var component = this;
		this.surveyResponse = this.surveyResponseService.cachedSurveyResponse;
		if(!component.surveyResponse){
			component.surveyResponseService.fetchSurveyResponse().subscribe(function(response){
				component.surveyResponse = response;
			});
		}
	}

	isMultipleChoice = function(question){
		var out = true;
		for(var i=0;i<question.options.length;i++){
			if(question.options[i].option_type == "integer"){
				out = false;
				break;
			}
		}
		return out;
	}

	getSelectedAnswer(question){
		return this.surveyResponse.survey[0].questions[question].student_selected_response.option_value
	}
	isAnswerSelected(question, answer) {
	if (this.surveyResponse.survey[0].questions[question].student_selected_response) {
		var selectedValue = this.surveyResponse.survey[0].questions[question].student_selected_response.option_value;
		var currentValue = this.surveyResponse.survey[0].questions[question].options[answer].option_value;
		if (selectedValue === currentValue) {
			return 'bg-info-disabled';
			}
		}
	}
}



	
