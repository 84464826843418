import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import {catchError, retryWhen} from "rxjs/operators";
import {StudentErrorService} from "./student-error.service";


@Injectable({providedIn:'root'})
export class StudentSurveyService {
	survey;
	constructor(
		private http: HttpClient,
		private errorService: StudentErrorService
	){}

	generateHttpOptions = function(){
		return {
			headers: new HttpHeaders({ 
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': window.sessionStorage.authToken
			})
		};
	};

	fetchSurvey = function(){
		let url = environment.baseStudentUrl;
		url += '/organizations/' +  window.sessionStorage.organizationName;
		url += "/surveys-launched/" + window.sessionStorage.surveyKey;
		let observable = this.http.get(url, this.generateHttpOptions()).pipe(
			retryWhen(this.errorService.checkForRetryCode()),
			catchError(this.errorService.displayErrorMessage)
		);
		return observable;
	};
}
