import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule }    from '@angular/common/http';

import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { LoginComponent } from './components/login.component';
import { SurveyComponent } from './components/survey.component';
import { SurveyReviewComponent } from './components/survey-review.component';
import { LogoutModal } from './components/logout-modal.component';


@NgModule({
	declarations: [
		LoginComponent,
		SurveyComponent,
		SurveyReviewComponent,
		AppComponent,
		LogoutModal,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		FormsModule,
		HttpClientModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule
	],
	providers: [],
	bootstrap: [AppComponent]
})
export class AppModule { }
