import { environment } from '../../environments/environment';
import { Component } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AuthenticationDetails } from 'amazon-cognito-identity-js/src/index';
import { StudentSurveyService } from '../services/student-survey.service'
import { LoginService } from '../services/student-login.service'
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import * as $ from "jquery";
import { Title } from '@angular/platform-browser';

@Component({
	selector: 'app-root',
	templateUrl: './partials/login.component.html',
	styleUrls: ['./styles/login.component.scss','./styles/common.scss']
})
export class LoginComponent {
	email = "";
	isClosed = false;
	loadingBar = false;
	surveyCloseDate;
	loginData;
	consentStatementAgreedTo = false;
	submitButtonHasBeenPressed = false;
	currentYear = moment().year();
	pageTitle = 'Insights Student Survey - Enter Details';
	constructor(
		private router : Router,
		private route: ActivatedRoute,
		private studentSurveyService : StudentSurveyService,
		private loginService : LoginService,
		private toastr: ToastrService,
		private titleService: Title
	){ }

	ngOnInit() {
		var self = this;
		this.route.queryParams.subscribe(params => {
			window.sessionStorage.surveyKey = params.survey_key;
			self.loginService.fetchLoginData().subscribe(function(response){
				self.loginData = response;
				self.isClosed = moment().toDate() < moment.utc(response.closed).local().toDate();
				self.surveyCloseDate = moment.utc(response.closed).local().format('MMMM DD, YYYY');
			});
		});
		this.titleService.setTitle(this.pageTitle);
	}


	submit = function() {
		if (this.consentStatementAgreedTo && !this.submitButtonHasBeenPressed) {
			this.submitButtonHasBeenPressed = true;
			this.loadingBar = true;

			var studentEmail;
			if (this.loginData.required_domain) {
				studentEmail = this.email + this.loginData.required_domain
			} else {
				studentEmail = this.email
			}
			window.sessionStorage.studentEmail = studentEmail.toLowerCase();
			this.login(this, window.sessionStorage.surveyKey, this.consentStatementAgreedTo);
		}
	};


	login = function(self, surveyKey, consentStatementAgreedTo){
		let observable = self.loginService.login(window.sessionStorage.studentEmail, surveyKey, consentStatementAgreedTo);
		observable.subscribe(
			function(response){
				window.sessionStorage.organizationName = response.organization.organization_name;
				if(response.user.first_name){
					window.sessionStorage.firstName = response.user.first_name;
				}
				if(response.user.last_name){
					window.sessionStorage.lastName = response.user.last_name;
				}
				if(response.user.id){
					window.sessionStorage.studentId = response.user.id;
				}
				let authToken = response.session_token;
				authToken = "Bearer " + authToken;
				window.sessionStorage.authToken = authToken;
				self.goToSurvey(self);
			},
			function(error){
				self.toastr.error("", error.error.user_message, {closeButton: true, positionClass: 'toast-bottom-center'});
				self.submitButtonHasBeenPressed = false;
				self.loadingBar = false;
			}
		);
	}

	
	goToSurvey = function(self){
		self.router.navigate(['/survey']);
	};

}



