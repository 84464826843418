import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {LoginComponent} from "./components/login.component";
import {SurveyComponent} from "./components/survey.component";
import {SurveyReviewComponent} from "./components/survey-review.component";

const routes: Routes = [
	{path: 'login', component: LoginComponent},
	{path: 'survey', component: SurveyComponent},
	{path: 'survey-review', component: SurveyReviewComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
