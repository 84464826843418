import { Component} from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
	selector: 'logout-modal',
	templateUrl: './partials/logout-modal.html',
	styleUrls: ['./styles/modal.scss',"./styles/common.scss"]
})
export class LogoutModal {

	timeoutDuration = 15000;
	inTransit = false;

	constructor(
		private router: Router
	){}


	ngOnInit() {
		setTimeout(this.returnToLogin, this.timeoutDuration);
	}


	returnToLogin(){
		if(!this.inTransit){
			let observable = this.router.navigateByUrl("login?survey_key=" + window.sessionStorage.surveyKey);
			this.inTransit = true;
		}
	}
}
