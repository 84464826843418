import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {interval, Observable, of, throwError} from "rxjs";
import {concatMap, count, delay, flatMap, retry, take, tap, timeout} from "rxjs/operators";
import {HttpErrorResponse} from "@angular/common/http";



@Injectable({providedIn:'root'})
export class StudentErrorService {

	constructor(
		private toastr: ToastrService
	) { }

	displayErrorMessage = (error: HttpErrorResponse) => {
		if (error.error && error.error.user_message) {
			this.toastr.error("", error.error.user_message, {closeButton: true, positionClass: 'toast-bottom-center'});
		}
	};

	checkForRetryCode = () => {

		return (errors) => {
			return errors.pipe(
				take(5),
				concatMap((error, index) =>
					(error['status'] == 429 && index == 4) || (error['status'] != 429)
						? throwError(error)
						: of(index)),
				delay(2000)
			)
		}
	}
}
