import {environment} from '../../environments/environment';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, retryWhen} from "rxjs/operators";
import {StudentErrorService} from './student-error.service';


@Injectable({providedIn: 'root'})
export class LoginService {

	constructor(
		private http: HttpClient,
		private errorService: StudentErrorService
	) {
	}

	generateHttpOptions = function () {
		let headers = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			})
		}
		return headers;
	}

	fetchLoginData = function (){
		var url = environment.baseStudentUrl;
		url += "/surveys-launched/" + window.sessionStorage.surveyKey;
		url += "/login";
		var promise = this.http.get(url, this.generateHttpOptions()).pipe(
			retryWhen(this.errorService.checkForRetryCode()),
			catchError(this.errorService.displayErrorMessage)
		);
		return promise;
	};

	login = function (studentEmail, surveyKey, consentStatementAccepted) {
		var url = environment.baseStudentUrl;
		url += "/surveys-launched/" + window.sessionStorage.surveyKey;
		url += "/session";
		var json = {
			"email": studentEmail,
			"survey_key": surveyKey,
			"consent_statement_accepted": consentStatementAccepted,
		}
		var observable = this.http.post(url, json, this.generateHttpOptions());
		return observable;
	};

}
